import React from "react";

const CTATextSection = () => {
  return (
    <section className="cta-text">
      <div className="cta-text-content container" data-aos="fade-right">
        <div className="cta-text-content-left">
          <h2 className="heading-secondary">
            Software development services for businesses
          </h2>
        </div>
        <div className="cta-text-content-right" data-aos="fade-up">
          <p className="paragraph-primary">
            After years of experience working on various projects, we have
            developed a proven method for using technology to solve business
            problems. Our unique approach combines a deep understanding of
            the technology and the specifics of each project.
          </p>
        </div>
      </div>
    </section>
  );
};

export default CTATextSection;
