import * as React from "react";

import "../styles/styles.scss";

import Services from "../components/Services/Services.jsx";

const IndexPage = () => {
  return <Services />;
};

export default IndexPage;
