import React from "react";

const WhatWeDoSectionItem = ({ src, title, text }) => {
  return (
    <div
      className="what-we-do-content-list-item"
      data-aos="fade-up"
      data-aos-delay={100}
    >
      <img src={src} alt="" />
      <h4 className="heading-third">{title}</h4>
      <p className="paragraph-primary">{text}</p>
    </div>
  );
};

export default WhatWeDoSectionItem;
