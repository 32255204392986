import React from "react";

import WhatWeDoSectionItem from "./WhatWeDoItem";
import MobileIcon from "../../../images/icons/mobileIcon.png";
import WebIcon from "../../../images/icons/webIcon.png";
import SmartTvIcon from "../../../images/icons/smartTvIcon.png";
import IotIcon from "../../../images/icons/iotIcon.png";
import EcommerceIcon from "../../../images/icons/ecommerceIcon.png";
import ConsultingIcon from "../../../images/icons/consultingIcon.png";

const WhatWeDoSection = () => {
  return (
    <section className="what-we-do">
      <div className="what-we-do-content container">
        <div className="what-we-do-content-list">
          <WhatWeDoSectionItem
            src={MobileIcon}
            title="Mobile Development"
            text="Multi-device mobile apps enable constant communication with
            customers and employees, improving efficiency and effectiveness of
            company operations."
          />
          <WhatWeDoSectionItem
            src={WebIcon}
            title="Web Development"
            text=" A responsive website with clear goals can improve business and
            expand online reach. Customized web solutions can enhance
            operations and provide convenient access to information and
            offerings for customers."
          />
          <WhatWeDoSectionItem
            src={IotIcon}
            title="IoT / Smart City"
            text="Our specialists offer customized end-to-end solutions, from
            designing smart city solutions to managing IoT activities. Our
            solutions help cities expand operations and increase efficiency."
          />
          <WhatWeDoSectionItem
            src={EcommerceIcon}
            title="eCommerce"
            text="Our solutions allow companies to expand their business online and
            increase sales. With our services, companies can create a
            professional online store to reach new customers and provide them
            with a convenient way to shop."
          />
          <WhatWeDoSectionItem
            src={SmartTvIcon}
            title="Smart TV"
            text="Our team of designers and developers creates cutting-edge
            applications for Smart TV platforms and manages and optimizes the
            distribution of these applications. With our solutions, companies
            can develop their TV business channels and expand their reach."
          />
          <WhatWeDoSectionItem
            src={ConsultingIcon}
            title="Consulting & Expertise"
            text="There could be an issue with your product that you are unable to
            resolve, or your product may already be functioning well but could
            potentially be improved. In either situation, it is important to
            bring in IT experts to talk to you about your
            product and figure out how to assist you in achieving your goals."
          />
        </div>
      </div>
    </section>
  );
};

export default WhatWeDoSection;
