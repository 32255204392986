import { Link } from "gatsby";
import React from "react";

import RectangleImg from "../../../images/rectangle.svg";
import SkewRectangleHeroImg from "../../../images/skewRectangleHero.png";

const HeroSection = () => {
  return (
    <section
      className="hero-section"
      style={{ backgroundImage: `url(${SkewRectangleHeroImg})` }}
    >
      <div className="hero-section-content container" data-aos="fade-up">
        <div className="hero-section-content-left">
          <h1 className="heading-primary">
            Technology solutions that support businesses
          </h1>
          <p className="paragraph-primary">
            We provide custom technology solutions for businesses, with over a
            decade of experience and a track record of success with dozens of
            customers across various industries. Our services include 
            requirement analysis, design, implementation, maintenance and support.
          </p>
          <p className="paragraph-primary">
            To see some samples of how we used technology to solve 
            business challenges, please visit our <Link to="/portfolio">portfolio</Link> page.
          </p>
        </div>
        <div className="hero-section-content-right" data-aos="fade-left">
          <img src={RectangleImg} alt="" />
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
