import React, { useEffect } from "react";
import { Seo } from "../SEO/Seo.jsx";
import CTABanner from "../CTABanner/CTABanner";

import MainMenu from "../MainMenu/MainMenu";
import Footer from "../Footer/Footer.jsx";

import HeroSection from "./components/HeroSection.jsx";
import CTATextSection from "./components/CTATextSection.jsx";
import WhatWeDoSection from "./components/WhatWeDoSection.jsx";
import ManufactureCycle from "./components/ManufactureCycle.jsx";

import AOS from "aos";
import "aos/dist/aos.css";

const Services = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true,
    });
  }, []);
  return (
    <main>
      <Seo
        title="Services | Gammasoft: Custom Software Development Company"
        description="Find out what we do and how we can help solve your company's technology problems."
      />
      <MainMenu />
      <HeroSection />
      <CTATextSection />
      <WhatWeDoSection />
      <ManufactureCycle />
      <CTABanner />
      <Footer />
    </main>
  );
};

export default Services;
