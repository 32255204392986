import React from "react";

const ManufactureCycle = () => {
  return (
    <section className="manufacture-cycle-section" id="manufacture-cycle">
      <div className="manufacture-cycle-section-content container">
        <div className="manufacture-cycle-section-content-top">
          <div
            className="manufacture-cycle-section-content-top-left"
            data-aos="fade-right"
          >
            <h2 className="heading-secondary">
              We handle the entire development cycle
            </h2>
          </div>
          <div
            className="manufacture-cycle-section-content-top-right"
            data-aos="fade-left"
          >
            <p className="paragraph-primary">
              We take care of every step in the software manufacturing process. 
              From design and development, to production rollout and quality control, 
              we handle it all. We work closely with our customers to understand 
              their needs and requirements. This allows us to maintain high standards 
              and deliver consistently excellent products to our customers. 
            </p>
          </div>
        </div>
        <ul className="manufacture-cycle-section-content-list">
          <li
            className="manufacture-cycle-section-content-list-item"
            data-aos="fade-up"
            ata-aos-delay={100}
          >
            <h1 className="gradient-number">01</h1>
            <h3 className="heading-third">Requirements analysis</h3>
            <p className="paragraph-primary">
              We take the time to carefully analyze the requirements of each
              project before we begin. This allows us to fully understand the
              scope and objectives of the work, and ensures we can deliver
              a solution that meets our customers' needs.
            </p>
          </li>
          <li
            className="manufacture-cycle-section-content-list-item"
            data-aos="fade-up"
            data-aos-delay={200}
          >
            <h1 className="gradient-number">02</h1>
            <h3 className="heading-third">Architecture design</h3>
            <p className="paragraph-primary">
              We understand the importance of having a well-designed
              architecture for any IT system or application. That's why we take
              the time to carefully plan and design the architecture of each
              project, using industry-standard techniques and best practices.
            </p>
          </li>
          <li
            className="manufacture-cycle-section-content-list-item"
            data-aos="fade-up"
            data-aos-delay={300}
          >
            <h1 className="gradient-number">03</h1>
            <h3 className="heading-third">GUI designs</h3>
            <p className="paragraph-primary">
              We specialize in designing user-friendly and visually appealing
              graphical user interfaces (GUIs) for software applications and
              websites. Our team of experienced designers uses the latest tools
              and techniques to produce flexible designs that our customers can review
              and comment on before we start coding.
            </p>
          </li>
          <li
            className="manufacture-cycle-section-content-list-item"
            data-aos="fade-up"
            data-aos-delay={400}
          >
            <h1 className="gradient-number">04</h1>
            <h3 className="heading-third">Product development</h3>
            <p className="paragraph-primary">
              We are proud to create high-quality products that meet the
              specified requirements and GUI designs. We have a team of skilled workers 
              who are dedicated to producing software that is reliable, and of 
              the highest standard. We use agile development methodologies and continuous 
              integration techniques to ensure that the product is delivered on time and 
              within budget.
            </p>
          </li>
          <li
            className="manufacture-cycle-section-content-list-item"
            data-aos="fade-up"
            data-aos-delay={500}
          >
            <h1 className="gradient-number">05</h1>
            <h3 className="heading-third">Manual and automatic tests</h3>
            <p className="paragraph-primary">
              We conduct both manual and automatic tests to verify that our
              products meet our high standards. Our team of experienced testers
              uses a range of tools and techniques to conduct both functional
              and non-functional tests, covering a wide range of scenarios and
              conditions.
            </p>
          </li>
          <li
            className="manufacture-cycle-section-content-list-item"
            data-aos="fade-up"
            data-aos-delay={600}
          >
            <h1 className="gradient-number">06</h1>
            <h3 className="heading-third">Technical and user documentation</h3>
            <p className="paragraph-primary">
              Our technical documentation includes information on the product's
              architecture, design, and implementation, as well as instructions
              on how to install, configure, and use the product. Our user
              documentation includes clear and concise instructions on how to
              use the product, as well as troubleshooting guides and other
              resources to help users get the most out of it.
            </p>
          </li>
          <li
            className="manufacture-cycle-section-content-list-item"
            data-aos="fade-up"
            data-aos-delay={650}
          >
            <h1 className="gradient-number">07</h1>
            <h3 className="heading-third">Production rollouts</h3>
            <p className="paragraph-primary">
              We work closely with our customers in assisting them during the 
              production deployments of the software we create. We can also 
              setup production and staging environments. 
            </p>
          </li>
          <li
            className="manufacture-cycle-section-content-list-item"
            data-aos="fade-up"
            data-aos-delay={700}
          >
            <h1 className="gradient-number">08</h1>
            <h3 className="heading-third">Maintenance and support</h3>
            <p className="paragraph-primary">
              We provide support and maintenance services to help our
              customers get the most out of their product over time. We use 
              ticket tracking systems for a better management of issues and 
              requests reported by the customers. We also provide regular updates 
              and upgrades to keep the product running on the latest software and 
              hardware platforms.
            </p>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default ManufactureCycle;
